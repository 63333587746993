<!-- Finder particular de Apds -->

<template>
  <div class="apd_Fras_F">
    <base_F
      v-if="schema_fras"
      :schema="schema_fras"
      :header="header"
      :titulo="titulo"
      :tipo="tipo"
      :sGrid="sGrid"
      :Entorno="Entorno"
      :btn_cerrarForm="btn_cerrarForm"
      :dblclick="dblclick"
      :selectable="selectable"
      @onEvent="event_capture"
    >
      <!-- Scoped Slot Campos del Grid -->
      <template v-slot:fields_grids="{ Sitem, Sheader }">
        {{ get_fieldGrid(Sitem, Sheader) }}
      </template>
    </base_F>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_F = () => plugs.groute("base_F.vue", "base");

export default {
  mixins: [mixinFinder],
  components: { base_F },
  props: {
    schema: { type: Object, default: null },
    id: { type: String, default: "" },
    accion: { type: String, default: "" },
    edicion: { type: Boolean, default: false },
    tipo: { type: String, default: "finder" },
    btra: { type: [Array, Object], default: undefined },
    header: { type: Boolean, default: true },
    btn_cerrarForm: { type: Boolean, default: false },
    dblclick: { type: String, default: "0" },
    selectable: { type: Boolean, default: true }
  },

  data() {
    return {
      // variables de configuración
      Entorno: undefined,

      // variables del componente
      finder: "apdsF", // api particular
      titulo: "Facturas - Liquidaciones", // título a mostrar en la cabecera del Finder
      schema_fras: null, // schema

      sGrid: {
        headers: [],
        records: [],
        header_ini: [
          { text: "Liquidación", value: "liq", width: "10%" },

          { text: "Fecha", value: "fh", width: "15%" },

          { text: "Exp", value: "exped", width: "5%" },

          { text: "Factura", value: "fra_doc", width: "15%" },

          { text: "Importe", value: "tot", width: "20%" },

          { text: "Acciones", value: "acciones", sortable: false, width: "20%" }
        ]
      }
    };
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log(
        "*** event_capture apd_Fras_F. Accion: " + evt.accion + " ***"
      );

      if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
      if (evt.accion === "cerrar_form")
        this.$emit("onEvent", { accion: "cerrar_fras" });
    },

    // obtengo el schema del Finder
    async ini() {
      // cargo schema finder presupuestos
      this.schema_fras = await this.$store.dispatch("get_schema", {
        tipo: this.tipo,
        name: this.finder,
        btra: this.btra
      });

      // obtengo presupuestos Grid
      await this.get_liquidaciones();
    },

    // guardo id Padre en el campo relacionado y obtengo lineas de facturas de liquidaciones
    async get_liquidaciones() {
      await this.get_records({ datos_filtro: { id: this.id } });
    },

    // devuelvo cada campo del grid
    get_fieldGrid(row, header) {
      switch (header.value) {
        case "fh":
          return this.sql2visual({ format: "fh" }, row.item.fh);

        default:
          return row.item[header.value];
      }
    },

    // muestro factura en PDF
    pdf() {
      alert("factura en PDF");
    },

    // envío factura a Email
    email() {
      alert("factura a Email");
    }
  }
};
</script>
